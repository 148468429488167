::selection {
  color: black;
  background: yellowgreen;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: animation;
  -moz-osx-font-smoothing: grayscale;
  color: whitesmoke;
  background-color: #0a0a0a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-weight: 900;
    color: rgb(164, 216, 189);
}


/* Navbar start */

.NavBar-lg{
  background-color: #181818;
  padding: 1vh 0;;
  box-shadow: 5px 10px 18px #404040;
  height: auto;
}

#logo{
  display: inline;
  color: #ffffff;
}

#logo img{
  width: 3vw;
  height: auto;
  /* margin: 0 1vw; */
}

#logo h1{
  display: inline;
}

#logo h1:hover{
  text-shadow: 5px 10px 18px yellowgreen;
}

.NavBar-lg li{
  list-style-type: none;
  display: inline;
  margin-inline-end: 2vw;
  color: #7b7b7b;
}

.NavBar-lg ul li:nth-child(-n+2){
  margin-left: 25vw;
}

.NavBar-lg ul li:last-child{
  margin-left: 20vw;
}

.NavBar-lg a{
  text-decoration: none;
  color: #cccccc;
}

.NavBar-lg a:hover{
  color:yellowgreen;
  transition: 0.5s;
  text-shadow: 5px 10px 18px rgb(156, 184, 100);
}

.chat-box-btn{
  padding: 1.2vh;
  border-radius: 10px;
    background-color: rgb(156, 197, 73);
}

.chat-box-btn:hover{
  transition: 0.5s;
  border: 1px dashed yellowgreen;
  background-color: #181818;
  color: yellowgreen;
  box-shadow: 5px 10px 18px rgb(156, 184, 100);
  transform: scale(1.1);
}
/* Navbar end */



/* -------------------------------------------------------------------------------- */



/* Home start */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');

#home{
  width: 100%;
  height: 68vh;
}

.hero-section{
  margin: 15vh 0 0 0;
  color: white;
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
}

.heading-text{
  font-size: 60px;
  color: yellowgreen;
  word-spacing: 5px;
}

.words-change{
  color: #7b7b7b;
}

.words-change::selection {
  color: yellowgreen;
  background: #7b7b7b;
}

.secondary-heading{
  color: #cccccc;
  font-weight: lighter;
}

@import url('https://fonts.googleapis.com/css2?family=Nabla&display=swap');
.drasticcoder{
  color: blueviolet;
  font-family: 'Nabla', cursive;
}

.socials-section{
  background-color: #0a0a0a;
  border: 1px solid yellowgreen;
  box-shadow: 5px 10px 18px rgb(156, 184, 100);
  border-radius: 20px;
  margin: 10vh 0 0 27.5vw  ;
  height: 15vh;
  width: 45vw;
  /* position: absolute; */
}

.socials{
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 5vh 2.5vw 0vh 2.6vw;
}

.socials a{
  color: yellowgreen;
}

.socials a:hover{
  color: white;
}

.socials:hover{
  animation: rotate-icon 0.8s ease-in-out;
  box-shadow: 5px 10px 18px rgb(156, 184, 100);
}

@keyframes rotate-icon {
  from{
     transform:rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  } 
}

/* Home end */

/* ------------------------------------------------------------ */

.page{
  margin: 8vh 7vw 0 7vw;
  word-spacing: 0.3vw;
  line-height: 5.5vh;
}

.neonText:hover {
  color: #fff;
  animation: neon 0.3s infinite;
}

@keyframes neon {
  from{
    color: whitesmoke;
    transform: scale(1.0001);
  }
  to{
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #0fa,
        0 0 82px #0fa,
        0 0 92px #0fa,
        0 0 102px #0fa,
        0 0 151px rgb(0, 255, 60);
  }
}

/* ------------------------------------------------------------ */

/* About start */

#about h1{
  margin-bottom: 5vh;
}

#about li{
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size:larger;
}

#about li a{
  color: whitesmoke;
}

/* About end */


/* ------------------------------------------------------------ */

/* Project start */

.projectContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 5vh 0 0 0;
}

.project{
  margin: 2vh 2vw 0 2vw;
  width: 30vw;
  height: 30vh;
  border-radius: 10px;
  color: whitesmoke;
  padding: 2vh 0 0 0;
  border: 3px dashed yellowgreen;
}

.project h2{
  text-align: center;
}

.project:hover{
  background-color: #181818;
  box-shadow: 5px 10px 18px rgb(156, 184, 100);
  transform: scale(2);
  transition: 1s;
}



/* Project end */













/* ------------------------------------------------------------ */



/* Footer start */

footer{
  text-align: center;
  color: #7b7b7b;
}

/* Footer end */